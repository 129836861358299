import React from "react"
import PageTemplate from '../components/pageTemplate'
import ProductDisplay from '../components/product-display'
import { graphql } from 'gatsby'

export default ({data}) => (
  <PageTemplate title="Potset Greek Yoghurt" img={data.fileName}>
    <div className="text-center">
      <p>Raybek Foods Natural Greek Potset yoghurt is the raw alternative to the Raybek Foods flagship flavoured Greek Yoghurt.  At its natural strained state, Pot set yoghurt boasts substantial health benefits due to the natural production process used and no added flavouring or sweeteners. Raybek Greek Potset Yoghurt portrays the true of meaning of ‘Culture’ by incorporating the traditional methods of production into making such a beautiful and healthy product.</p>
      <p>A recipe this good only comes from years of experience!</p>
      <p>The benefits of Potset Yoghurt include:</p>
    </div>
    <div className="d-flex justify-content-center">
      <ul>
        <li>High Source of Protein</li>
        <li>Great Calcium boost for teeth and bones</li>
        <li>No added sugar</li>
        <li>Gluten Free</li>
        <li>High in B12</li>
        <li>Great post workout fuel</li>
        <li>96% Fat Free</li>
      </ul>
    </div>
    <div className="text-center">
      <p>Since being introduced to the market several years ago, the Raybek Natural Potset Yoghurt has gained a large following from the fitness and health food community.
      Get on board and try it today!</p>
    </div>
    <ProductDisplay products={data.potset}/>
  </PageTemplate>
)

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "mango-banner.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    potset: allPrismicProducts(filter: {data: {product_type: {eq: "potset"}}}) {
      edges {
        node {
          ...ProductInfo
        }
      }
    }
  }
`
